<script setup>
import { reactive, onMounted } from "vue";

let castContext = null;

// Fonction pour attendre l'initialisation complète de Chromecast
function waitForChromecastAPI() {
  return new Promise((resolve, reject) => {
    if (window.chrome && window.chrome.cast && window.chrome.cast.isAvailable) {
      resolve();
    } else {
      // Utiliser le callback global pour vérifier quand l'API devient disponible
      window.__onGCastApiAvailable = (isAvailable) => {
        if (isAvailable) {
          resolve();
        } else {
          reject("Chromecast API non disponible.");
        }
      };
    }
  });
}

// Initialiser Chromecast
function initializeCast() {
  try {
    const castContext = cast.framework.CastContext.getInstance();
    castContext.setOptions({
      receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
    console.log("Chromecast initialisé avec succès !");
  } catch (error) {
    console.error("Erreur lors de l'initialisation de Chromecast :", error);
  }
}

onMounted(async () => {
  try {
    await waitForChromecastAPI(); // Appel de la fonction pour vérifier l'API Chromecast
    initializeCast(); // Initialiser Chromecast après la disponibilité de l'API
  } catch (error) {
    console.error(error);
  }
});

const movies = reactive([
  {
    id: 1,
    title: "Inception",
    description: "Un voleur s'infiltre dans les rêves pour voler des secrets.",
    poster: "https://via.placeholder.com/150x220?text=Inception",
    video: "https://www.w3schools.com/html/mov_bbb.mp4",
    isPlaying: false,
  },
  {
    id: 2,
    title: "Matrix",
    description: "Un hacker découvre la vérité sur son monde.",
    poster: "https://via.placeholder.com/150x220?text=Matrix",
    video: "https://www.w3schools.com/html/movie.mp4",
    isPlaying: false,
  },
  {
    id: 3,
    title: "Interstellar",
    description: "Un voyage au-delà des étoiles pour sauver l'humanité.",
    poster: "https://via.placeholder.com/150x220?text=Interstellar",
    video: "https://www.w3schools.com/html/mov_bbb.mp4",
    isPlaying: false,
  },
]);

function playMovie(movie) {
  movie.isPlaying = true;
}

function stopMovie(movie) {
  movie.isPlaying = false;
}

function castToChromecast(movie) {
  if (!castContext) {
    alert("Chromecast n'est pas initialisé. Veuillez réessayer.");
    return;
  }

  const session = castContext.getCurrentSession();

  if (!session) {
    alert("Pas de session Chromecast active.");
    return;
  }

  const mediaInfo = new chrome.cast.media.MediaInfo(movie.video, "video/mp4");
  const request = new chrome.cast.media.LoadRequest(mediaInfo);

  session
    .loadMedia(request)
    .then(() => {
      console.log(`La vidéo "${movie.title}" est en cours de diffusion.`);
    })
    .catch((err) => {
      console.error("Erreur de diffusion Chromecast :", err);
    });
}
</script>

<template>
  <div class="movie-list">
    <div v-for="movie in movies" :key="movie.id" class="movie-card">
      <div class="media-container">
        <img v-if="!movie.isPlaying" :src="movie.poster" :alt="movie.title" />
        <video
          v-else
          class="video-player"
          :src="movie.video"
          controls
          autoplay
        ></video>
      </div>
      <div class="movie-info">
        <h2>{{ movie.title }}</h2>
        <p>{{ movie.description }}</p>
        <div class="buttons">
          <button v-if="!movie.isPlaying" @click="playMovie(movie)">
            Regarder
          </button>
          <button v-else @click="stopMovie(movie)">Fermer</button>
          <button @click="castToChromecast(movie)">Chromecast</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Styles principaux */
.movie-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

/* Styles des cartes */
.movie-card {
  display: flex;
  flex-direction: column;
  background-color: #1f1f1f;
  border: 1px solid #333;
  border-radius: 10px;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.movie-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
}

.media-container {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.media-container img,
.media-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.movie-info {
  padding: 10px;
  text-align: center;
  background-color: #222;
}

.movie-info h2 {
  font-size: 1.2em;
  color: #f5f5f5;
  margin: 10px 0;
}

.movie-info p {
  font-size: 0.9em;
  color: #bbb;
  margin: 0 0 10px;
}

.movie-card .buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.movie-card button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #333;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.movie-card button:hover {
  background-color: #444;
}
</style>
