<template>
  <div id="app">
    <header>
      <!-- <h1>Distribution de Films</h1> -->
      <div class="user-settings">
        <button @click="connectUser">Se connecter</button>
        <button @click="openSettings">Paramètres</button>
      </div>
    </header>
    <main>
      <MovieList />
    </main>
  </div>
</template>

<script setup>
import MovieList from "./components/MovieList.vue";

function connectUser() {
  alert("Connexion utilisateur non disponible !");
}

function openSettings() {
  alert("Accès aux paramètres.");
}
</script>

<style>
/* Global styles */
#app {
  font-family: "Roboto", Arial, sans-serif;
  color: #f5f5f5;
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header styles */
header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  background-color: #1f1f1f;
  border-bottom: 1px solid #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

header h1 {
  font-size: 1.8em;
  color: #f5f5f5;
}

.user-settings {
  display: flex;
  justify-content: end;
}

.user-settings button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.user-settings button:hover {
  background-color: #444;
}

/* Main content styles */
main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
</style>
